import * as React from 'react';

import { useAppSelector } from '../../../app/hooks';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import {
    Box,
    Modal,
} from '@mui/material';
import { LOCAL_CONSTANT } from '../../Const';

import {
    PlanAreaType,
    existingSejStoreType,
    impactCounterType,
    listInterFace,
} from "../Interface";

import { drawPlanArea } from "../viewData/PlanArea";
import { drawExistingSejPlanArea } from "../viewData/ExistingSejPlanArea";
import { drawImpactCounterSummary } from "../viewData/ImpactCounter";
import { useUtilityContext } from '../../../utility-provider';
import { useUserContext } from '../../../user-provider';
import {
    setImpactLabelMarker,
    exsitesStoreCode,
    callApi,
} from "./AddImpactFunction";
import {
    DISTANCE,
} from "./LocationImpactFunction";

const render = (status: Status) => {
    return <h1>{status}</h1>;
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "90vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export interface confirmImpactModalProps {
    props: {
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        mode: string,
        plan_area_id: string,
        latlng: any,
        locationImpactList: any,
        targetImpact: any,
        handleSetReadApi: any,
    }
}

const ConfirmImpactModal: React.FC<confirmImpactModalProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();
    // 初期
    const [open, setOpen] = React.useState(false);
    const [map, setMap] = React.useState<google.maps.Map>();

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);


    // マップ中央
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(props.latlng);
    // マップ描画
    const [load, setLoad] = React.useState(false)
    // 各処理完了フラグ
    const [hasPlanArea, setHasPlanArea] = React.useState<boolean>(false);
    const [hasExSejStore, setHasExSejStore] = React.useState<boolean>(false);
    // 
    const [polyLineButton, setPolyLineButton] = React.useState<string>("")
    // 選択タブ
    const [selectTab, setSelectTab] = React.useState<string>("")
    // モード
    const [mode, setMode] = React.useState<string>("");
    // 取得結果格納用
    const [planAreaList, setPlanAreaList] = React.useState<PlanAreaType[] | undefined | null>();
    const [planArea, setPlanArea] = React.useState<PlanAreaType>();
    const [existingSejStoreList, setExistingSejStoreList] = React.useState<existingSejStoreType[]>([]);
    const [impactCounterList, setImpactCounterList] = React.useState<impactCounterType[]>([]);

    // 既存店データ表示用
    const [exSejStoreList, setExSejStoreList] = React.useState<listInterFace[]>([]);

    // 既存店データ表示用
    const [impactList, setImpactList] = React.useState<listInterFace[]>([]);
    // 対象既存店
    const [targetExSejStore, setTargetExSejStore] = React.useState<existingSejStoreType[]>([]);

    // 閉じる
    const handleClose = () => {
        setOpen(false)
        props.handleModal(false)
        //
        setLoad(false)
        setHasPlanArea(false)
        setHasExSejStore(false)
        setPolyLineButton("")
        setSelectTab("")
        setImpactCounterList([])
    };

    const onIdle = (m: google.maps.Map) => {
        setCenter(m.getCenter()!.toJSON());
        // Map保存
        setMap(m)
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
            setLoad(true);
            setMode(props.mode);
        }
    }, [props.open])

    // マップ中央調整
    React.useEffect(() => {
        if (map) {
            const lanlot = map.getCenter()!.toJSON()
            if (lanlot.lat !== center.lat && lanlot.lng !== center.lng) {
                map.setCenter(center)
            }
        }
    }, [center])

    React.useEffect(() => {
        if (load) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner()
            }
            // 候補地呼び出し
            callApi('planArea', objLongPageParam.planAreaId, center.lat, center.lng, DISTANCE, objLocationBase.location_excel_sequence, user)
                .then((res: any) => {
                    setCenter({
                        lat: res[0]['centerlat'],
                        lng: res[0]['centerlon'],
                    })
                    setPlanAreaList(res)
                    setPlanArea(res[0])
                    setHasPlanArea(true)

                }).catch((e) => {
                    console.log(e)
                }).finally(() => {
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    if (planArea) {
                        setCenter({
                            lat: planArea['centerlat'],
                            lng: planArea['centerlon'],
                        })
                    }
                    setLoad(false)
                })
        }
    }, [load])

    React.useEffect(() => {
        if (hasExSejStore) {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            // マーカーラベル表示
            drawImpactLabelMarker()
            setLoad(false)
        }
    }, [hasExSejStore]);

    const drawImpactLabelMarker = () => {
        if (map) {
            const tmp: any = []
            const tmpList: any = []
            props.locationImpactList.map((row: any) => {
                const data = JSON.parse(row.location_impact_common_number)
                data.map((element: any) => {
                    if (element) {
                        if (!tmp.includes(element.counter_uuid)) {
                            tmp.push(element.counter_uuid)
                            tmpList[element.counter_uuid] = element
                        }
                    }
                })
            })
            const labelTmp: any = []
            // 交差点情報
            // 既存店前
            props.locationImpactList.map((data: any) => {
                const element = JSON.parse(data.location_impact_counter)
                element.map((data: any) => {
                    data.map((row: any) => {
                        let impactLabel = ""
                        if (!labelTmp.includes(row.counter_uuid)) {
                            if (row.counter_seq === '1') {
                                impactLabel += LOCAL_CONSTANT.LABEL.FRONT_FOR_EXISTING_STORES + ' '
                            }
                            if (tmpList[row.counter_uuid]) {
                                // 交差点あり
                                impactLabel += LOCAL_CONSTANT.LABEL.INTERSECTION + tmpList[row.counter_uuid]['common_number']
                            }
                        }
                        if (impactLabel !== "") {
                            const label = setImpactLabelMarker(row.counter_centerlat, row.counter_centerlon, impactLabel)
                            label.setMap(map)
                            labelTmp.push(row.counter_uuid)
                        }
                    })
                })
            })
            // 未登録交差点の非表示
            impactList.map((impactRow: any) => {
                if (!labelTmp.includes(impactRow.key)) {
                    impactRow.arrowPoly.setMap(null)
                    impactRow.dummy.setMap(null)
                }
            })
        }
    }

    /**
     * 候補地処理
     */
    React.useEffect(() => {
        if (map && planAreaList && planAreaList.length !== 0) {
            drawPlanArea(planAreaList, map);
            setHasPlanArea(true)
            loadExStore()
        }
    }, [planAreaList]);

    const loadExStore = () => {
        // 既存店、候補地呼び出し
        callApi('getLongPageData', objLongPageParam.planAreaId, center.lat, center.lng, DISTANCE, objLocationBase.location_excel_sequence, user)
            .then((res: any) => {
                setExistingSejStoreList(res.getExistingSejPlanArea)
                setImpactCounterList(res.getImpactCounter)
            })
    }


    /**
     * 既存店処理
     */
    React.useEffect(() => {
        if (
            map
            && planAreaList && planAreaList.length !== 0
            && existingSejStoreList && existingSejStoreList.length !== 0
        ) {
            const list = drawExistingSejPlanArea(
                existingSejStoreList,
                map,
                setExSejStoreList,
                planAreaList
            )
            list.forEach((data) => {
                const hasImpact = exsitesStoreCode(props.locationImpactList, data)
                if (hasImpact) {
                    // 登録既存店名ラベル表示
                    const label = setImpactLabelMarker(data.data.centerlat, data.data.centerlon, data.data.tenpo_name)
                    label.setMap(map)
                } else {
                    // 非登録既存店アイコン非表示
                    data.marker.setMap(null)
                }
            })
        }
    }, [existingSejStoreList]);


    /**
     * 影響度処理
     */
    React.useEffect(() => {
        if (map && impactCounterList && impactCounterList.length !== 0) {
            const list = drawImpactCounterSummary(
                impactCounterList,
                "all"
            )
            list.map((row: any) => {
                row.arrowPoly.setMap(map!)
                row.dummy.setMap(map!)
            })
            setImpactList(list)
            setHasExSejStore(true)
        }
    }, [impactCounterList])

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box sx={{ width: '100%', height: '90vh', display: 'flex' }}>
                        <Wrapper apiKey={"AIzaSyBH0T_KDXD97Dg4ZA0lPi_WRZQ-c09HNnY"} libraries={['drawing', 'marker']} render={render} version={'quarterly'}>
                            <MapBase
                                style={{ width: '100%', height: '100%' }}
                                center={center}
                                onIdle={onIdle}
                            >
                            </MapBase>
                        </Wrapper>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}


interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
    onIdle?: (map: google.maps.Map) => void;
}

const MapBase: React.FC<MapProps> = ({
    style,
    children,
    onIdle,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                mapId: 'e42bfbd48d30d1a0',
                disableDefaultUI: false,
                keyboardShortcuts: false,
                disableDoubleClickZoom: false,
                streetViewControl: false,
                zoomControl: true,
                scaleControl: true,
                clickableIcons: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scrollwheel: false,
                center: options.center,
                zoom: 16
            }));
        }
    }, [ref, map]);


    React.useEffect(() => {
        if (map) {
            ["idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onIdle]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};


export default ConfirmImpactModal;